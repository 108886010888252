import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import React from 'react'

function StructuredData() {
    const jsonLd = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Rennaï',
        alternateName: 'Rennaï',
        description:
            'Rennaï is a Self-Care and Beauty Boutique at Royalmount in Montréal, Canada. At Rennaï, we are dedicated to helping individuals achieve well-being through 360° self-care in a highly accessible setting. Inspired by the French word Renaissance, meaning rebirth and renewal, we are committed to empowering individuals on their journey towards self-care, self-love and self-discovery.',
        url: 'https://www.rennai.com/en',
        foundingDate: '2024',
        logo: getAssetUrl('assets/svg/brand-logo.svg'),
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'Canada',
            postalCode: 'H4P 2N2',
            streetAddress: '8500 Decarie Blvd, 8th Floor',
            addressLocality: 'Mount Royal, Quebec'
        },
        sameAs: [
            'https://www.facebook.com/RennaiSelfcare',
            'https://www.linkedin.com/company/rennai/',
            'https://www.tiktok.com/@rennaiselfcare',
            'https://www.instagram.com/rennaiselfcare/'
        ]
    }

    const jsonLdFr = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Rennaï',
        alternateName: 'Rennaï',
        description:
            "Rennaï est une boutique de soins de bien-être et de beauté située au Royalmount à Montréal, Canada. Chez Rennaï, nous nous engageons à aider les individus à atteindre le bien-être par le biais de soins personnels grâce à une approche 360° dans un environnement très accessible. Inspiré par le mot français Renaissance, qui signifie renaissance et renouveau, nous nous engageons à aider les personnes à prendre soin d'elles-mêmes, à s'aimer et à se découvrir.",
        url: 'https://www.rennai.com/fr',
        foundingDate: '2024',
        logo: getAssetUrl('assets/svg/brand-logo.svg'),
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'Canada',
            postalCode: 'H4P 2N2',
            streetAddress: '8500 Decarie Blvd, 8th Floor',
            addressLocality: 'Mount Royal, Quebec'
        },
        sameAs: [
            'https://www.facebook.com/RennaiSelfcare',
            'https://www.linkedin.com/company/rennai/',
            'https://www.tiktok.com/@rennaiselfcare',
            'https://www.instagram.com/rennaiselfcare/'
        ]
    }

    return (
        <>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLd)}}
            />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLdFr)}}
            />
        </>
    )
}

export default StructuredData
