import {
    Flex,
    Skeleton
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'

import HomeServiceScroller from './home-service-scroller'

export const HomeServiceSection = ({data, itemWidth, id, variant}) => {
    const carouselData = data?.carouselCollection?.items[0]

    return !carouselData ? (
        variant === 'event' ? (
            <Flex
                flexDirection={{base: 'column', lg: 'row'}}
                gap={{base: '12px', lg: '32px'}}
                m={{base: '10px', lg: '30px'}}
                justifyContent="space-between"
                alignItems="center"
                my={{base: '20px', lg: '80px'}}
            >
                <Flex
                    flexDirection="column"
                    gap="20px"
                    width={{base: '100%', lg: '40%'}}
                    alignItems={{base: 'center', lg: 'start'}}
                >
                    <Skeleton width="70%" height="50px" />
                    <Skeleton width="90%" height="100px" />
                    <Skeleton width="30%" height={{base: '35px', lg: '30px'}} />
                </Flex>

                <Flex
                    flexDirection={{base: 'column', lg: 'row'}}
                    justifyContent="flex-end"
                    width="100%"
                    gap={{base: '12px', lg: '20px'}}
                >
                    <Flex
                        flexDirection="column"
                        height="400px"
                        gap="30px"
                        width={{base: '100%', lg: '40%'}}
                        padding={{base: '20px', lg: 'none'}}
                    >
                        <Flex
                            flexDirection="column"
                            gap={{base: '8px', lg: '10px'}}
                        >
                            <Skeleton width="100%" height="150px" />
                            <Skeleton width="70%" height="30" />
                            <Skeleton width="20%" height="20px" />
                            <Skeleton width="60%" height="40px" />
                        </Flex>

                        <Skeleton width="20%" height="50px" />
                    </Flex>
                    <Flex
                        flexDirection="column"
                        height="400px"
                        gap="30px"
                        width="40%"
                        display={{base: 'none', lg: 'flex'}}
                    >
                        <Flex flexDirection="column" gap="10px">
                            <Skeleton width="100%" height="150px" />
                            <Skeleton width="70%" height="30" />
                            <Skeleton width="20%" height="20px" />
                            <Skeleton width="60%" height="40px" />
                        </Flex>

                        <Skeleton width="20%" height="50px" />
                    </Flex>
                </Flex>
            </Flex>
        ) : (
            <Flex
                flexDirection="column"
                mx="30px"
                mb="30px"
                gap={{base: '16px', lg: '50px'}}
                my={{base: '30px', lg: 'none'}}
            >
                <Flex justifyContent="space-between" width="100%">
                    <Skeleton width={{base: '70%', lg: '30%'}} height="40px" />
                    <Skeleton width="10%" height="30px" />
                </Flex>
                <Flex
                    gap={{base: '8px', lg: '50px'}}
                    flexDirection={{base: 'column', lg: 'row'}}
                >
                    <Skeleton
                        height={{base: '200px', lg: '400px'}}
                        width={{base: '100%', lg: '55%'}}
                    />
                    <Flex
                        gap="2px"
                        alignItems="start"
                        justifyContent="flex-start"
                        flexDirection="column"
                        width={{base: '100%', lg: '40%'}}
                        my="20px"
                    >
                        <Skeleton
                            height={{base: '32px', lg: '80px'}}
                            width={{base: '100%', lg: '70%'}}
                        />
                        <Skeleton
                            height={{base: '20px', lg: '40px'}}
                            width="30%"
                        />
                        <Skeleton
                            height={{base: '36px', lg: '90px'}}
                            width={{base: '90%', lg: '60%'}}
                        />
                        <Skeleton
                            height={{base: '40px', lg: '30px'}}
                            width={{base: '100%', lg: '20%'}}
                        />
                    </Flex>
                </Flex>
            </Flex>
        )
    ) : (
        <HomeServiceScroller
            data={carouselData}
            itemWidth={itemWidth}
            variant={variant}
            id={id}
        />
    )
}

HomeServiceSection.displayName = 'HomeServiceSection'

HomeServiceSection.propTypes = {
    data: PropTypes.shape({
        carouselCollection: PropTypes.shape({
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    alignment: PropTypes.string,
                    cta: PropTypes.shape({
                        text: PropTypes.string,
                        url: PropTypes.string,
                        type: PropTypes.string,
                        isExternal: PropTypes.bool
                    }),
                    description: PropTypes.string,
                    carouselCollection: PropTypes.shape({
                        items: PropTypes.array
                    })
                })
            )
        })
    }),
    itemWidth: PropTypes.any,
    id: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['event', 'service'])
}

export default HomeServiceSection
