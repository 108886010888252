import PropTypes from 'prop-types'
import React from 'react'
import {v4 as uuidv4} from 'uuid'

import {
    Flex,
    Heading,
    HStack,
    Text
} from '@salesforce/retail-react-app/app/components/shared/ui'

import Link from '../link'
import ScrollerNavigationButtons from '../product-scroller/scroller-navigation-buttons'

const EventHeader = ({data: eventSectionData, styles, scroll, itemsCount}) => {
    return (
        <Flex
            flexDirection="column"
            maxWidth="390px"
            marginRight={{lg: '100px'}}
            sx={styles}
        >
            <Heading
                as={eventSectionData.headingType}
                className={styles[eventSectionData.headingType]}
                key={uuidv4()}
            >
                {eventSectionData.heading}
            </Heading>
            <Text className="p" color="#FFFFFF" key={uuidv4()}>
                {eventSectionData.description}
            </Text>
            <HStack>
                {itemsCount > 1 && (
                    <ScrollerNavigationButtons scroll={scroll} />
                )}
                <Link {...eventSectionData.cta} />
            </HStack>
        </Flex>
    )
}

EventHeader.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        headingType: PropTypes.string,
        description: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            type: PropTypes.string,
            isExternal: PropTypes.bool
        })
    }),
    scroll: PropTypes.func,
    itemsCount: PropTypes.number,
    styles: PropTypes.object
}

export default EventHeader
