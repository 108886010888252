import {
    Box,
    Flex,
    Stack,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import EventHeader from './event-section-header'
import ServiceCard from './service-card'

import {colors, styleConfigChakraKeys} from '../../constants'
import {useProductScroller} from '../product-scroller/use-product-scroller'
import BookingHeader from './bookings-header'

const Wrapper = ({variant, data, children, scroll}) => {
    const styles = useMultiStyleConfig(
        styleConfigChakraKeys.homeServiceSection,
        {
            variant
        }
    )

    if (variant === 'event') {
        return (
            <Flex
                {...styles.container}
                bg={data.theme.backgroundColor || colors.white}
                color={data.theme.color || colors.black}
            >
                <EventHeader
                    data={data}
                    styles={styles}
                    itemsCount={data.carouselCollection?.items?.length || 0}
                    scroll={scroll}
                />
                {children}
            </Flex>
        )
    }

    if (variant === 'service') {
        return (
            <Flex
                direction="column"
                px={{base: '16px', lg: '51px'}}
                pb={{md: '15px'}}
            >
                <BookingHeader
                    data={data}
                    itemsCount={data.itemsCollection?.items?.length || 0}
                    scroll={scroll}
                />
                <Flex {...styles.container}>{children}</Flex>
            </Flex>
        )
    }

    return <Box>{children}</Box>
}

Wrapper.propTypes = {
    variant: PropTypes.oneOf(['event', 'service']),
    data: PropTypes.object,
    children: PropTypes.node,
    scroll: PropTypes.func
}

const HomeServiceScroller = ({data, itemWidth, id, variant}) => {
    const {scrollRef, totalScrolls, scrollPosition, moveByChip, scroll} =
        useProductScroller({
            itemWidth,
            scrollContainerId: id,
            items: data?.itemsCollection?.items,
            defaultNumberOfItems: {base: 1}
        })
    const serviceCards = data?.itemsCollection?.items

    return (
        <Stack overflowX="hidden" position="relative" width="full">
            <Stack
                ref={scrollRef}
                sx={{
                    scrollSnapType: 'x mandatory',
                    WebkitOverflowScrolling: 'touch',
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    }
                }}
                id={id}
            >
                <ServiceCard serviceCard={serviceCards[0]} variant={variant} />
            </Stack>
        </Stack>
    )
}

HomeServiceScroller.propTypes = {
    data: PropTypes.shape({
        alignment: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            type: PropTypes.string,
            isExternal: PropTypes.bool
        }),
        description: PropTypes.string,
        carouselCollection: PropTypes.shape({
            items: PropTypes.array
        }),
        itemsCollection: PropTypes.shape({
            items: PropTypes.array
        })
    }),
    itemWidth: PropTypes.any,
    id: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['event', 'service'])
}

export default HomeServiceScroller
