import React from 'react'

import {
    Box,
    Grid,
    Skeleton
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'

import {useCategorySection} from '../../clients/contentful/hooks/use-category-section.hook'
import CategoryCard from './category-card'

const CategoryGrid = ({sectionNameKey}) => {
    const {data} = useCategorySection(sectionNameKey)
    const dataItems = data?.contentGridCollection.items[0].itemsCollection.items
    const columns = data?.contentGridCollection.items[0].columns
    const numColumns = 3
    return !dataItems ? (
        <Box paddingX={{base: '17px', md: '32px'}}>
            <Skeleton width="100%" height="600px"></Skeleton>
            <Grid
                paddingTop={{base: '60px', md: '40px'}}
                templateColumns={{
                    base: '1',
                    md: `repeat(3, 1fr)`
                }}
                columnGap={{
                    md: '28px',
                    xl: '18px'
                }}
                rowGap={{base: '30px', md: '20px'}}
            >
                {[1, 2, 3, 4, 5, 6].map((index) => {
                    return (
                        <Skeleton
                            key={index}
                            height={{base: '400px', xl: '200px'}}
                        />
                    )
                })}
            </Grid>
        </Box>
    ) : (
        <Box
            paddingLeft={{base: '17px', md: '32px', lg: '0'}}
            paddingRight={{base: '17px', md: '36px', lg: '0'}}
        >
            <Grid
                templateColumns={{
                    base: columns.base,
                    lg: `repeat(${columns.xl}, 1fr)`
                }}
                columnGap={{
                    base: '18px',
                    lg: '36px',
                    xl: '52px'
                }}
                rowGap={{base: '29px', md: '25px'}}
            >
                {dataItems.map((item, index) => {
                    return (
                        <CategoryCard
                            key={index}
                            data={item}
                            index={index}
                            total={dataItems.length}
                        />
                    )
                })}
            </Grid>
        </Box>
    )
}

CategoryGrid.propTypes = {
    sectionNameKey: PropTypes.array
}

export default CategoryGrid
