import {
    Flex,
    Heading,
    HStack,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'

import {styleConfigChakraKeys} from '../../constants'
import {useBooxi} from '../../hooks/use-booxi'
import Link from '../link'
import ScrollerNavigationButtons from '../product-scroller/scroller-navigation-buttons'

const BookingHeader = ({data, scroll, itemsCount}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.bookingSection)
    const {instantiateBooxi} = useBooxi()

    return (
        <Flex
            justifyContent="space-between"
            alignItems={{base: 'flex-start', lg: 'center'}}
        >
            <Heading
                variant={data.headingType}
                as={data.headingType}
                {...styles[data.headingType]}
            >
                {data.heading}
            </Heading>
            <HStack gap={{lg: '51px'}}>
                {itemsCount > 1 && (
                    <ScrollerNavigationButtons scroll={scroll} />
                )}
                <Link
                    buttonStyles={styles.link}
                    {...data.cta}
                    onLinkClick={() => {
                        instantiateBooxi()
                    }}
                />
            </HStack>
        </Flex>
    )
}

BookingHeader.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        headingType: PropTypes.string,
        description: PropTypes.string,
        fixedWidth: PropTypes.any,
        cta: PropTypes.shape({
            sys: PropTypes.shape({
                id: PropTypes.string
            }),
            __typename: PropTypes.string,
            text: PropTypes.string,
            url: PropTypes.string,
            externalUrl: PropTypes.string,
            openLinkInNewTab: PropTypes.bool,
            iconName: PropTypes.any,
            theme: PropTypes.any,
            type: PropTypes.string,
            isExternal: PropTypes.bool
        }),
        itemsCollection: PropTypes.shape({
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    heading: PropTypes.string,
                    headingType: PropTypes.string,
                    currency: PropTypes.string,
                    price: PropTypes.number,
                    time: PropTypes.number,
                    description: PropTypes.string,
                    cta: PropTypes.shape({
                        sys: PropTypes.shape({
                            id: PropTypes.string
                        }),
                        __typename: PropTypes.string,
                        text: PropTypes.string,
                        url: PropTypes.string,
                        externalUrl: PropTypes.string,
                        openLinkInNewTab: PropTypes.bool,
                        iconName: PropTypes.any,
                        theme: PropTypes.any,
                        type: PropTypes.string,
                        isExternal: PropTypes.bool
                    }),
                    image: PropTypes.shape({
                        desktop: PropTypes.shape({
                            url: PropTypes.string,
                            title: PropTypes.string
                        })
                    })
                })
            )
        })
    }),
    scroll: PropTypes.func,
    itemsCount: PropTypes.number
}

export default BookingHeader
