import React from 'react'

import {
    Box,
    Grid,
    Skeleton
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {useContentGridSection} from '../../clients/contentful/hooks'
import {contentfulQueryKeys} from '../../constants'
import IngredientCard from './ingredient-card'

const IngredientGrid = () => {
    const {data: homePageBottomSection} = useContentGridSection(
        contentfulQueryKeys.homePageBottomSection
    )

    const dataItems =
        homePageBottomSection?.contentGridCollection.items[0].itemsCollection
            .items
    const columns =
        homePageBottomSection?.contentGridCollection.items[0].columns
    return !dataItems ? (
        <Box>
            <Skeleton width="100%" height="600px"></Skeleton>
            <Grid
                // paddingTop={{base: '60px', lg: '40px'}}
                templateColumns={{
                    base: '1',
                    lg: `repeat(2, 1fr)`,
                    xl: `repeat(3, 1fr)`
                }}
                columnGap={{
                    lg: '28px',
                    xl: '18px'
                }}
                rowGap={{base: '30px', lg: '20px'}}
            >
                {[1, 2, 3, 4, 5, 6].map((index) => {
                    return (
                        <Skeleton
                            key={index}
                            height={{base: '400px', xl: '200px'}}
                        />
                    )
                })}
            </Grid>
        </Box>
    ) : (
        <Box paddingBottom={{base: '40px', lg: '85px'}}>
            <Grid
                gap={{base: '25px', lg: '15px', xl: '22px'}}
                templateColumns={{
                    base: columns.base,
                    lg: 'repeat(2,1fr)'
                }}
            >
                {dataItems?.map((item, index) => (
                    <IngredientCard
                        isBanner={true}
                        data={item}
                        key={index}
                        index={index}
                        total={dataItems.length}
                        clickable={!!item.media}
                    />
                ))}
            </Grid>
        </Box>
    )
}

export default IngredientGrid
