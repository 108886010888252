import PropTypes from 'prop-types'
import React from 'react'

import {
    Box,
    Flex,
    Heading,
    Skeleton,
    Stack,
    Text,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import _ from 'lodash'

import {colors, styleConfigChakraKeys} from '../../constants'
import CTASection from './cta-section'
import HeroImageContainer from './hero-image-container'
import CategoryCard from '../category-grid/category-card'
import Link from '../link'

const HeroSection = ({data, ...otherProps}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.heroSection)
    const heroSection = data?.homeHeroSectionCollection?.items[0]
    const heading = heroSection?.heading
    const subheading = heroSection?.subTitle
    const ctaTop = heroSection?.ctaTop
    const ctaBottom = heroSection?.ctaBottom
    const ctaItems = _.filter([ctaTop, ctaBottom])
    const media = heroSection?.media
    const mediaAlignment = heroSection?.mediaAlignment
    const numberOfColumns = heroSection?.numberOfColumns

    return !heroSection ? (
        <Flex
            margin="3%"
            alignItems="center"
            flexDirection={{base: 'column-reverse', lg: 'row'}}
            gap="30px"
        >
            <Flex
                gap="20px"
                alignItems="start"
                justifyContent="flex-end"
                flexDirection="column"
                width={{base: '100%', lg: '40%'}}
            >
                <Skeleton height={{base: '80px', lg: '100px'}} width="80%" />
                <Skeleton height={{base: '60px', lg: '90px'}} width="90%" />
                <Skeleton height={{base: '60px', lg: '90px'}} width="90%" />
            </Flex>
            <Skeleton
                height={{base: '300px', lg: '600px'}}
                width={{base: '100%', lg: '55%'}}
            />
        </Flex>
    ) : (
        <>
            {mediaAlignment == 'overlap' ? (
                <Flex
                    flexDirection="column"
                    gap={{base: '3px', md: '10px', lg: '18px'}}
                    height={{base: '367px', md: '650px', lg: '730px'}}
                    backgroundImage={{
                        base: `url(${media?.mobile?.url})`,
                        md: `url(${media?.desktop?.url})`
                    }}
                    color={colors.white}
                    padding={{base: '197px 16px', lg: '328px 68px'}}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    backgroundPosition={{base: 'top', lg: 'center'}}
                    {...otherProps}
                >
                    <Heading
                        color={colors.white}
                        fontSize={{base: '32px', md: '48px', lg: '64px'}}
                        lineHeight={{base: '35px', md: '45px', lg: '58px'}}
                        fontFamily="Raleway"
                        fontWeight="300"
                        dangerouslySetInnerHTML={{
                            __html: heading
                        }}
                    />
                    <Text
                        fontFamily="Raleway"
                        color={colors.white}
                        fontSize={{base: '15px', md: '24px', lg: '32px'}}
                        fontWeight="300"
                    >
                        {subheading}
                    </Text>
                    <Box maxWIdth="883px">
                        {ctaItems?.map((cta, index) => (
                            <Link
                                {...cta}
                                marginTop={{base: '2px', md: '5px', lg: '7px'}}
                                key={index}
                                buttonStyles={{
                                    color: colors.white,
                                    textDecoration: 'underline',
                                    fontSize: {base: '10px', lg: '25px'},
                                    fontFamily: 'Raleway'
                                }}
                                _hover={{
                                    svg: {opacity: '1'},
                                    background: 'transparent',
                                    backgroundColor: 'transparent'
                                }}
                            />
                        ))}
                    </Box>
                </Flex>
            ) : (
                <Flex {...styles.container} {...otherProps}>
                    {mediaAlignment === 'left' ? (
                        <>
                            <HeroImageContainer
                                media={media.desktop}
                                autoplayVideo={true}
                            />
                            <CTASection heading={heading} ctaItems={ctaItems} />
                        </>
                    ) : (
                        <>
                            <CTASection heading={heading} ctaItems={ctaItems} />
                            <HeroImageContainer
                                media={media.desktop}
                                autoplayVideo={true}
                            />
                        </>
                    )}
                </Flex>
            )}
        </>
    )
}

HeroSection.propTypes = {
    data: PropTypes.shape({
        homeHeroSectionCollection: PropTypes.shape({
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    heading: PropTypes.string,
                    ctaTop: PropTypes.shape({
                        text: PropTypes.string,
                        url: PropTypes.string
                    }),
                    ctaBottom: PropTypes.shape({
                        text: PropTypes.string,
                        url: PropTypes.string
                    }),
                    media: PropTypes.shape({
                        desktop: PropTypes.shape({
                            url: PropTypes.string,
                            type: PropTypes.string
                        })
                    }),
                    mediaAlignment: PropTypes.oneOf(['left', 'right']),
                    numberOfColumns: PropTypes.string
                })
            )
        })
    })
}

export default HeroSection
